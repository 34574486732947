import React from 'react';
import { graphql, navigate } from 'gatsby';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { accountProfileSelector, accountHasInsuranceSelector } from 'state/account/account.selectors';
import { useSelector } from 'react-redux';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import { Container } from 'react-bootstrap';

const Insurance = () => {
    const { t } = useTranslation();
    const profileObject = useSelector(accountProfileSelector);
    const hasInsurance = useSelector(accountHasInsuranceSelector);

    // If the user does not have insurance, redirect them to the main profile
    // page.
    if (!hasInsurance && typeof window !== 'undefined') {
        navigate('/secure/profile/personal-info');
        return null;
    }

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.eyebrowText`)}
            title={t(`pages.profile.insurance.title`)}
            heading={t(`pages.profile.insurance.heading`)}
        >
            <Container>
                <p className="profile-form-instructions">{t(`pages.profile.insurance.memberID`)}</p>
                <Container>
                    <p>{profileObject?.cardholderID}</p>
                </Container>
            </Container>
        </ProfileLayout>
    );
};

export default Insurance;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
